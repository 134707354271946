@tailwind base;
@tailwind components;
@tailwind utilities;
 
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,500;1,600&display=swap');

*, *::after , *::before{
  margin: 0;
  padding: 0;
}
.no-scroll {
  overflow: hidden;
}

.hero-panner{
  /* background-image: url('./assets/3d-modern-techno-background-with-abstract-cyber-particles.jpg'); */
  /* background-image: url('./assets/autodesk_3\ min\ \(1\).mp4'); */
  /* background-size: cover; */
  position: relative;
  z-index: 0;
}
#myVideo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;

}
@media (max-width: 1200px) {
#myVideo {
  object-fit: contain;
top: 0;

}
  
}
@media (max-width: 767px) {
#myVideo {
top: -200px;

}
  
}
@media (max-width: 575px) {
#myVideo {
top: 0;

}
  
}
.powered-by-container {
  position: relative;
  width: 60px; /* Set the width as per your design */
  height: 50px; /* Set the height as per your design */
  overflow: hidden; /* Hide overflow content */
  background-color: black;
  border-radius: 40px;
  transition: all 0.5s;
}

.logo {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.5s ease; /* Transition the transform property */
}

.text {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  opacity: 0; /* Initially hide the text */
}
.powered-by-container:hover {
  width: 230px;
}
.powered-by-container:hover .logo {
  left: 75%; /* Slide the logo to the right */
}

.powered-by-container:hover .text {
  opacity: 1; /* Make the text visible */
  transition: opacity 1s 0.3s ease ; /* Transition the opacity property */
color: white;
}
.powered-by-container:not(:hover) .text {
  opacity: 0; /* Hide the text when not hovered */
}

@media screen and (max-width: 768px) {
  .powered-by-container {
    width: 230px;
  }

  .powered-by-container .logo {
    left: 75%; /* Adjust logo position for mobile devices */
  }
  .powered-by-container .text {
    opacity: 1 !important; /* Make the text visible */
  
  }
}
/* 
@keyframes logo {
  from{
    transform:translateY(-50%) rotate(0deg)  ;
  }
  to{
    transform:translateY(-50%) rotate(360deg)  ;
  }
}
@keyframes logoback {
  from{
    transform:translateY(-50%) rotate(360deg)  ;
  }
  to{
    transform:translateY(-50%) rotate(0deg)  ;
  }
} */




::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f7eded;
padding: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(29, 28, 28);
}
::-moz-scrollbar-thumb {
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(68, 68, 237);

    cursor: pointer;
  
}














.editapp-mobile::-webkit-scrollbar {
  width: 5px;
}
.editapp-mobile::-webkit-scrollbar-thumb {
  background: #e38585;
cursor: pointer;
}
.editapp-mobile::-moz-scrollbar-thumb {
  cursor: pointer;
}
.cart::-webkit-scrollbar {
  width: 5px;
}
.cart::-webkit-scrollbar-thumb {
  background: #e38585;
cursor: pointer;
}
.cart::-moz-scrollbar-thumb {
  cursor: pointer;
}



@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


.signin-custom-pattern{
  z-index: -99;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23000000' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
